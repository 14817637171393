import React from 'react'
import Img from '../../Img'
import Button from '../../forms/Button'
import SearchIconThin from '../../icons/SearchIconThin'
import { generateUrl } from '../../../utils/Helper'

type Props = {
    lang: any
    langCode: string
}

const EntrustApartmentSection = ({ lang, langCode }: Props) => {
    return (
        <div className='w-full relative pt-0'>
            <div className='relative hidden lg:flex' style={{ height: 280 }}>
                <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/entrust-banner.png`} className='lg:flex hidden object-left object-cover rounded-xl' style={{
                    height: 280,
                }} />
            </div>
            <div className='relative w-full flex lg:hidden' style={{ paddingTop: '45%' }}>
                <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/banner_under_hero.png`} className='flex lg:hidden object object-cover w-full rounded-xl' />
            </div>
            <div className='w-2/3 z-1 lg:w-10/12 px-3 lg:px-16 absolute top-0 right-0 bottom-0 left-auto flex flex-col items-end justify-center'>
                <div className='sm:px-10 px-0'>
                    <p className='mb-4 lg:mb-2 text-white'>
                        <span className='text-sm lg:text-38px block'>{lang.homepage_entrust_subtitle1}</span>
                        <div className='hidden md:block'>
                            <span className='text-lg lg:text-38px block'>{lang.homepage_entrust_subtitle2} <strong>{langCode === 'id' ? 'Dijadiin Duit!' : 'Turn It Into Money!'}</strong></span>
                        </div>
                        <div className='block md:hidden'>
                            <span className='text-lg font-bold block'>{lang.homepage_entrust_subtitle2_mobile}</span>
                        </div>
                    </p>
                    <a href={generateUrl(langCode, `/listing`)}>
                        <div className='inline-flex bg-white border-2 border-white items-center rounded-full py-2 px-4 lg:py- lg:px-5'>
                            <span className='text-13px lg:text-xl font-bold text-darkblue'>
                                {lang.homepage_entrust_button}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default EntrustApartmentSection